import React from 'react'
import GlobalContextProvider  from './src/context/GlobalContextProvider'
import 'bootstrap/dist/css/bootstrap.min.css'
import './src/styles/app.css'



export const wrapRootElement = ({ element }) => {
  return (
    <GlobalContextProvider>{element}</GlobalContextProvider>      
  )
}

export const onInitialClientRender = () => {
  setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
  }, 1000)
}