exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-final-js": () => import("./../../../src/pages/final.js" /* webpackChunkName: "component---src-pages-final-js" */),
  "component---src-pages-holes-js": () => import("./../../../src/pages/holes.js" /* webpackChunkName: "component---src-pages-holes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-players-js": () => import("./../../../src/pages/players.js" /* webpackChunkName: "component---src-pages-players-js" */),
  "component---src-pages-render-js": () => import("./../../../src/pages/render.js" /* webpackChunkName: "component---src-pages-render-js" */),
  "component---src-pages-results-js": () => import("./../../../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */),
  "component---src-pages-rules-js": () => import("./../../../src/pages/rules.js" /* webpackChunkName: "component---src-pages-rules-js" */),
  "component---src-pages-scorecard-js": () => import("./../../../src/pages/scorecard.js" /* webpackChunkName: "component---src-pages-scorecard-js" */),
  "component---src-pages-selfie-js": () => import("./../../../src/pages/selfie.js" /* webpackChunkName: "component---src-pages-selfie-js" */),
  "component---src-pages-share-js": () => import("./../../../src/pages/share.js" /* webpackChunkName: "component---src-pages-share-js" */),
  "component---src-pages-welldone-js": () => import("./../../../src/pages/welldone.js" /* webpackChunkName: "component---src-pages-welldone-js" */)
}

